<template>
  <v-container>
    <v-layout row wrap justify-space-between class="">
      <v-flex xs12 sm2 md2 lg2>
        <v-btn
          style="float: left"
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md4 lg4 class="mr-1 mt-1">
        <v-select
          v-if="!currentBusiness"
          dense
          solo
          class="caption"
          :label="$t('select business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusinesses"
          v-model="selectedBusiness"
          @change="onSelectBusiness"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="text-right mr-1 mt-1">
        <v-select
          dense
          solo
          class="caption"
          :label="$t('select year')"
          item-text="businessName"
          item-value="id"
          :items="years"
          v-model="selectedYear"
          @change="onSelectYear"
        ></v-select>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="mb-3 px-3">
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md4 lg4 class="text-right">
        <v-btn dark small class="gradients" @click="printPage()">
          <v-icon small left>mdi-download</v-icon>
          {{ $t("download") }}
        </v-btn>
      </v-flex>
    </v-layout>

    <div class="body" id="print">
      <div class="container">
        <div class="row right">
          <div class="column-quarter left">&nbsp;</div>
          <div class="column-quarter">&nbsp;</div>
          <div class="column-quarter">&nbsp;</div>
          <div class="column-quarter">
            <span class="bold-text title">{{ title }}</span>
          </div>
        </div>
        <!-- business details -->

        <div class="row">
          <div class="column-half left">
            <div v-for="(item, i) in reportHeader" :key="i">
              <span class="bold-text"
                >{{ item.title }} :
                <span class="light-text">{{ item.value }}</span></span
              ><br />
            </div>
          </div>

          <div class="column-half left">&nbsp;</div>
        </div>
        <div class="row">
          <div class="column right">
            <button class="button gradients">{{ $t("summary") }}</button>
            <div v-for="item in dataSummary" :key="item.title">
              <div class="column-half left">
                <span class="bold-text black--text">{{ item.title }}</span>
              </div>
              <div class="column-half right">
                <span class="bold-text text-right success--text text--darken-2">
                  {{ item.value }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- detailed report -->
        <div class="row">
          <div class="column right">
            <button class="button gradients">
              {{ $t("detailed report") }}
            </button>
          </div>
        </div>

        <div class="row">
          <div class="column">
            <table id="summary">
              <tr>
                <th>{{ $t("month") }}</th>
                <th>{{ $t("total sales") }}(Kes)</th>
              </tr>
              <tbody>
                <tr v-show="!detailedReport.length">
                  <td
                    colspan="5"
                    class="caption font-weight-medium text-center"
                  >
                    {{ $t("no transactions recorded") }}
                  </td>
                </tr>
                <tr v-for="(item, i) in detailedReport" :key="i">
                  <td>{{ item.month }}</td>
                  <td>{{ item.total }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { printer } from "@/plugins/functions";
import {
  startOfYear,
  format,
  getMonth,
  getYear,
  endOfYear,
  setYear,
  differenceInYears,
} from "date-fns";
import db from "@/plugins/fb";
export default {
  data: () => ({
    selectedBusiness: "",
    sales: [],
    annualSales: [],
    user: [],
    selectedYear: "",
    years: [],
    detailedReport: [],
  }),
  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },

    currentBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },
    today() {
      return format(new Date(), "yyyy-MM-dd");
    },

    selectedBusinessName() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.businessName;
    },
    months() {
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    },
    dataSummary() {
      return [{ title: this.$t("total sales"), value: "" }];
    },
    title() {
      return this.$t("annual sales report");
    },
    reportHeader() {
      return [
        { title: this.$t("business name"), value: "" },
        { title: this.$t("email"), value: "" },
        { title: this.$t("address"), value: "" },
        { title: this.$t("phone no"), value: "" },
        { title: this.$t("website"), value: "" },
        { title: this.$t("date of report"), value: "" },
        { title: this.$t("report period"), value: "" },
      ];
    },
    selectedBusinessEmail() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.email;
    },

    selectedAddress() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return (
        selected.address.building +
        ", " +
        selected.address.city +
        ", " +
        selected.address.country
      );
    },
    selectedPhoneNumber() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.phone;
    },
    selectedWebsite() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.website;
    },
  },

  created() {
    this.getSales();

    this.getYears();
  },

  methods: {
    printer,
    async getYears() {
      let numberOfYears = differenceInYears(new Date(), new Date(1950, 1, 1));

      for (let i = 0; i <= numberOfYears; i++) {
        //get years from 1950
        this.years[i] = 1950 + i;
      }
    },

    goBack() {
      window.history.back();
    },

    getSales() {
      this.sales = [];
      if (this.currentBusiness) {
        db.collection("transactions")
          .where("businessId", "==", this.currentBusiness)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.sales.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.onCurrentBusiness();
          });
      } else {
        db.collection("transactions")
          .where("businessId", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.sales.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });
      }
    },

    //function called when month is changed
    onSelectYear() {
      //pass the selected month to a filter function
      this.yearFilteredSales(this.selectedYear);
    },

    setReportDates() {
      this.reportHeader[5].value = this.today;
      let startDate = format(startOfYear(new Date()), "yyyy-MM-dd");
      let endDate = format(endOfYear(new Date()), "yyyy-MM-dd");
      this.reportHeader[6].value = startDate + " - " + endDate;
    },

    //create a function to feed data according to set month mm is the year
    yearFilteredSales(mm) {
      let currentYear = mm;
      //create a new january 1st for selected year
      //let newDate = setYear(new Date(2014, 0, 1), mm);
      //set report dates
      this.reportHeader[5].value = this.today;
      let startDate = format(
        startOfYear(setYear(new Date(2014 - 0 - 1), mm)),
        "yyyy-MM-dd"
      );
      let endDate = format(
        endOfYear(setYear(new Date(2014 - 0 - 1), mm)),
        "yyyy-MM-dd"
      );
      this.reportHeader[6].value = startDate + " - " + endDate;
      //filter by year
      let annualSales = this.sales.filter(
        (item) => getYear(item.dateTime.toDate()) == currentYear
      );
      this.annualSales = annualSales;

      // detailed report data
      this.salesPerMonth(this.annualSales);
    },

    salesPerMonth(arr) {
      let sales = [];
      for (let i = 0; i < this.months.length; i++) {
        let monthTransactions = arr.filter(
          (item) => getMonth(item.dateTime.toDate()) == i
        );
        if (monthTransactions.length) {
          let monthSales = monthTransactions.map((item) => Number(item.amount));
          let monthSalesSum =
            monthSales.length > 1
              ? monthSales.reduce((prev, next) => prev + next)
              : monthSales[0];
          sales.push({
            month: this.months[i],
            total: monthSalesSum,
          });
        } else {
          sales.push({
            month: this.months[i],
            total: "No transactions recorded",
          });
        }
      }
      this.detailedReport = sales;
    },

    totalSales(arr) {
      let allAmounts = arr.map((item) => Number(item.amount));
      let sumAmounts =
        allAmounts.length > 1
          ? allAmounts.reduce((prev, next) => prev + next)
          : allAmounts[0] != null
          ? allAmounts[0]
          : 0;
      this.dataSummary[0].value = "KES " + sumAmounts;
    },

    // if on business Dashboard
    onCurrentBusiness() {
      let currentBusiness = this.ownersBusinesses.find(
        (item) => item.id == this.currentBusiness
      );
      this.reportHeader[0].value = currentBusiness.businessName;
      this.reportHeader[1].value = currentBusiness.email;
      this.reportHeader[2].value =
        currentBusiness.address.building +
        ", " +
        currentBusiness.address.city +
        ", " +
        currentBusiness.address.country;
      this.reportHeader[3].value = currentBusiness.phone;
      this.reportHeader[4].value = currentBusiness.website;
      this.setReportDates();

      // summary data
      this.totalSales(this.sales);

      // detailed report data
      this.salesPerMonth(this.sales);
    },

    // if on general dashboard
    onSelectBusiness() {
      (this.detailedReport = []), (this.dataSummary[0].value = "");
      this.reportHeader[0].value = this.selectedBusinessName;
      this.reportHeader[1].value = this.selectedBusinessEmail;
      this.reportHeader[2].value = this.selectedAddress;
      this.reportHeader[3].value = this.selectedPhoneNumber;
      this.reportHeader[4].value = this.selectedWebsite;
      this.setReportDates();

      let businessSales = this.sales.filter(
        (item) => item.businessId == this.selectedBusiness
      );

      // summary data
      this.totalSales(businessSales);

      // detailed report data
      this.salesPerMonth(businessSales);
    },

    printPage() {
      this.printer("print", "Annual sales report");
    },
    formatDate(val) {
      return format(val, "yyyy-MM-dd");
    },
  },
};
</script>
<style scoped>
.white-hr {
  border: 1px dashed white;
  background-color: white;
}
.black-hr {
  border-top: 1px solid #000000;
  height: 1px;
  border-radius: 5px;
}
.grey-hr {
  border-top: 1px solid #cecece;
  height: 1px;
  border-radius: 5px;
}
.black-background {
  background-color: #000000;
}
.gradients {
  background-image: linear-gradient(to bottom right, #0579a3, #005270);

  /* background-image: linear-gradient(to bottom right, #072033,#103c5e); */
}
.white-text {
  color: white;
}

.center {
  text-align-last: center;
}

.center {
  text-align-last: center;
}
.left {
  text-align-last: left;
}

.right {
  text-align-last: right;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.title {
  color: #ed6d1e;
}

.body {
  background-color: #e0e0e0;
}
.body .container {
  background: black (0, 0, 0); /* Fallback color */
  color: grey (126, 126, 126);
  font-size: 15px;
  background-color: white;
}
.bold-text {
  font-weight: bolder;
}
.light-text {
  font-weight: lighter;
}
.column {
  float: left;
  width: 100%;
  padding: 10px;
}

.column-half {
  float: left;
  width: 50%;
  padding: 10px;
}

.column-third {
  float: left;
  width: 33.33%;
  padding: 10px;
}

.column-two-third {
  float: left;
  width: 66.66%;
  padding: 10px;
}

.column-quarter {
  float: left;
  width: 25%;
  padding: 10px;
}

.column-three-quarter {
  float: left;
  width: 75%;
  padding: 10px;
}
.column-small {
  float: left;
  width: 15%;
  padding: 10px;
}
.column-extra-large {
  float: left;
  width: 85%;
  padding: 10px;
}
.column-forty-percent {
  float: left;
  width: 40%;
  padding: 10px;
}
.column-sixty-percent {
  float: left;
  width: 60%;
  padding: 10px;
}
.column-twenty-percent {
  float: left;
  width: 20%;
  padding: 10px;
}
.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  width: 100%;
}
#summary {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#summary td,
#summary th {
  border: 1px solid #ddd;
  padding: 8px;
}

#summary tr:nth-child(even) {
  background-color: #f2f2f2;
}

#summary tr:hover {
  background-color: #ddd;
}

#summary th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ef6c00;
  color: white;
}
</style>
